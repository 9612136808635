.text-heading-3 {
  font-size: 1.953125rem;
  line-height: 2.25rem;
}

.text-heading-4 {
  font-size: 1.5625rem;
  line-height: 1.25;
}

.font-body {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-serif {
  font-family: serif;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.min-h-\[100vh\] {
  min-height: 100vh
}

.max-width {
  max-width: 1244px;
}

.margin-to-bottom {
  margin-top: auto;
}

.margin-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.margin-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.margin-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gap-1 {
  gap: .25rem
}

.peer-margin-collapse+.peer-margin-collapse {
  margin-top: 0
}

li>p.peer-margin-collapse {
  margin: 0
}

li>ul.peer-margin-collapse {
  margin: 0
}

.rich-text-block>*:first-child {
  margin-top: 0;
}

.rich-text-block>*:only-child,
.rich-text-block>*:last-child {
  margin-bottom: 0;
}

.prose-default h1,
.prose-default h2,
.prose-default h4 {
  text-align: center
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.pr-0 {
  padding-right: 0rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between
}

.centeredItem {
  align-self: center;
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overscroll-none {
  overscroll-behavior: none
}

.user-selection-grid {
  display: grid;
  grid-template-columns: repeat(2, 204px);
  justify-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  max-width: 390px;
}

.purchase-grid {
  display: grid;
  grid-template-columns: 408px;
  justify-items: center;
  justify-content: center;
  gap: 10px;
}

.purchase-item {
  grid-column: auto / span 1;
}

.max-column {
  grid-column: 1 / -1;
  max-width: 390px;
}

@media (min-width: 816px) {
  .user-selection-grid {
    grid-template-columns: repeat(4, 204px);
    max-width: 1244px;
  }

  .max-column {
    max-width: 1244px;
  }

  .purchase-grid {
    grid-template-columns: repeat(2, 408px);
  }

  .purchase-item:nth-child(1) {
    align-self: end;
    justify-self: end;
  }

  .purchase-item:nth-child(2) {
    align-self: start;
    justify-self: start;
  }

  .purchase-item:nth-child(3) {
    /* Targeting the third item (Stripe Checkout) specifically */
    grid-column: 2;
    /* Place it in the second column */
    grid-row: 1 / span 2;
    /* Make it span two rows */
    justify-self: start;
    align-self: center;
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: start;
  }

  .md\:w-full {
    width: 95%;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pb-0 {
    padding-bottom: 0rem;
  }
}

@media (min-width: 1224px) {
  .user-selection-grid {
    grid-template-columns: repeat(6, 204px);
    max-width: 1244px;
  }

  .max-column {
    max-width: 1244px;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem
  }
}

.item {
  display: flex;
  width: 100%;
  color: var(--md-sys-color-on-background);
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-holder {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dropdown-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dropdown-right {
  display: flex;
  margin-left: auto;
  margin-right: 30px;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.single-column {
  grid-column: auto / span 2;
  max-width: 390px;
}

.expand-column {
  grid-column: 1 / -1;
}

@media (min-width: 816px) {
  .expand-column {
    grid-column: 3 / -1;
  }

  .max-column {
    max-width: 1244px;
  }
}


.chip-grid {
  display: grid;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  grid-template-columns: repeat(auto-fit, 128px);
  justify-items: center;
  row-gap: 10px;
  column-gap: 3px;
}

.item input[type="radio"] {
  margin-right: 10px;
}

.item input[type="checkbox"] {
  margin-right: 10px;
}

.item input[type="text"] {
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.text-field-short {
  height: 40px;
  width: 100px;
}

.text-field-long {
  height: 40px;
  width: 170px;
  padding: 0px;
  margin: 0px;
}

.item select {
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.item slider {
  width: 100%;
  margin-left: 10px;
}

.slider-container {
  position: relative;
  padding-top: 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  /* This will align the labels to the edges */
  margin-left: 40px;
  /* Align with slider start */
  width: 250px;
  /* Match the slider width */
}

.md-slider-size {
  width: 270px;
  margin-left: 30px;
}

.md-button-size {
  width: 150px;
}

.selected-item {
  text-align: center;
}

.card-outline {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 500px;
  padding: 12px;
}

.segments-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  margin: 5px;
  max-width: 370px;
}

.segments {
  display: inline-flex;
  justify-content: space-between;
  background: var(--md-sys-color-surface-variant);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 370px;
  padding: 12px 5px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.segments input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  color: var(--md-sys-color-on-surface-variant);
  padding: 2px;
  transition: color 0.5s ease;
}

.label,
.secondaryLabel {
  display: block;
  color: var(--md-sys-color-on-surface-variant);
  padding: 2px;
  transition: color 0.5s ease;
}

.segment.active .label,
.segment.active .secondaryLabel {
  font-weight: 700;
  color: var(--md-sys-color-on-primary);
}

.segment.active label {
  font-weight: 700;
  color: var(--md-sys-color-on-primary);
}

.segments::before {
  content: "";
  background: var(--md-sys-color-primary);
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  z-index: 0;
}

.segments.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

.no-select-segments .segment.active .label,
.no-select-segments .segment.active .secondaryLabel {
  color: var(--md-sys-color-on-secondary);
  /* Set to secondary color for active state */
}

.segments::before,
.no-select-segments::before {
  /* Ensures the highlight bar matches the active label color */
  background: var(--highlight-color, var(--md-sys-color-primary));
  /* Default to primary unless overridden */
}

.no-select-segments::before {
  --highlight-color: var(--md-sys-color-secondary);
  /* Override highlight color for NoSelect version */
}

.loader {
  border: 10px solid var(--md-sys-color-surface-variant);
  border-top: 10px solid var(--md-sys-color-primary);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-link {
  text-decoration: none;
}

.custom-link:hover {
  text-decoration: underline;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1244px;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .header-item:nth-child(2) {
    flex: 1 1 100%;
    /* Item B takes full width */
    order: 3;
    /* Moves item B to the end */
    text-align: center;
    margin-top: 10px;
  }
}

.logo img {
  height: 50px;
}